<template>
    <div>
        <div class="text-lg uppercase mb-4">
            Organisatorisches
        </div>

        <organization-update-form
            ref="organizationUpdateForm"
            :is-offer="true"
            v-model="value"
            :errors="errors"
        />

        <div class="flex justify-content-between">
            <div class="block">
                <Button
                    @click="prevPage()"
                    label="Zurück"
                    class="p-button-secondary p-button-sm cursor-pointer"
                />
            </div>
            <div class="block">
                <Button
                    @click="nextPage()"
                    label="Weiter"
                    class="p-button-sm cursor-pointer"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button/Button";
    import Value from "@/mixin/Value";
    import OrganizationUpdateForm from "../../../../../shared/components/OrganizationUpdateForm";

    export default {
        components: {OrganizationUpdateForm, Button},
        mixins: [Value],
        methods: {
            nextPage() {
                this.$emit('input', this.value);
                this.$emit('nextPage', {});
            },
            prevPage() {
                this.$emit('prevPage', {});
            }
        },
    }
</script>
